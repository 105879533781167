import React from 'react';
import PropTypes from 'prop-types';

const GradientBackground = ({ colors, children }) => {
  const [start, end] = colors;

  return (
    <div style={styles.container}>
      <svg height="100%" width="100%" style={styles.svg}>
        <defs>
          <linearGradient id="grad" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor={"#769B9D"} />
            <stop offset="35%" stopColor={"#769B9D"} />
            <stop offset="75%" stopColor={"#E4C890"} />
            <stop offset="100%" stopColor={"#E4C890"} />
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
      </svg>
      <div style={styles.content}>{children}</div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '100vh',
    width: '100vw',
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  content: {
    flex: 1,
    width: '100%',
  },
};

GradientBackground.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

export default GradientBackground;
