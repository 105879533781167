const dimensions = {
  window: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
};

export const styles = {
  gradientContainer: {
    position: "relative",
    flex: 1,
    flexGrow: 1,
  },
  codeFieldRoot: {
    width: "100%",
    gap: 3,
    backgroundColor: "red",
    marginRight: "auto",
    position: "absolute",
    top: 50,
    left: 50,
  },
  cell: (colors) => ({
    width: 35,
    height: 50,
    lineHeight: 46,
    borderRadius: 15,
    fontSize: 34,
    fontWeight: "600", // "semibold" is not a valid CSS value
    backgroundColor: "green",
    textAlign: "center",
  }),
  focusCell: {
    borderRadius: 15,
  },
  gradientOverlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0)",
    backgroundImage:
      "linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
    pointerEvents: "none",
  },
  scrollView: {
    flex: 1,
    overflowY: "auto", // Added for scrolling behavior
  },
  scrollViewContent: {
    justifyContent: "flex-end",
    padding: 10,
    flexGrow: 1,
    flex: 1,
    paddingBottom: 0,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: Math.min(500, dimensions.window.width) * 0.1,
    marginTop: 30,
  },
  container: {
    top: 150,
    paddingHorizontal: 20,
  },
};

export const textInputStyle = (colors) => ({
  flex: 1,
  borderRadius: 10,
  paddingLeft: 15,

  minHeight: 60,
  paddingRight: 15,
  color: "white",
  fontWeight: "bold",
  fontSize: 19,
  outline: "none",

  backgroundColor: "#769B9D",
  border: "0",
});

export const textInputState = (messages, lastMessageTyped, colors) => ({
  backgroundColor:
    messages[messages.length - 1].format && lastMessageTyped
      ? colors.primary
      : "transparent",
  opacity: messages[messages.length - 1].format && lastMessageTyped ? 1 : 0.5,
});

export const phoneInputStyles = {
  countryPickerButton: (colors) => ({
    borderRadius: 10,
    padding: 0,
    height: 60,
    paddingLeft: 10,
    paddingRight: 10,
    color: "black",
    backgroundColor: "#769B9D",
    paddingVertical: 0, // Make sure to have no vertical padding
    paddingHorizontal: 0, // Make sure to have no horizontal padding
    border: "none",
    
  }),
  textContainer: {
    padding: "0 !important",
    height: 50,
    backgroundColor: "transparent",
  },
  codeText: (colors) => ({
    padding: "0 !important",
    margin: "0 !important",
    color: "black",
    fontWeight: "bold",
    fontSize: 19,
  }),
  flagButton: {
    padding: "0 !important",
  },
  textInput: (colors) => ({
    height: 60,
    borderRadius: 10,
    outline: "none",
    border: "none",
    padding: 10,
    paddingLeft: 70,
    width: "100%",
    margin: "0 !important",
    backgroundColor: "#769B9D",
    color: "black",
    fontWeight: "bold",
    fontSize: 19,
    opacity: 1, // Ensure visibility
  }),
  container: {
    left: -20,
    width: dimensions.window.width - 150,
    backgroundColor: "transparent",
  },
};

export const sendButtonStyle = {
  position: "absolute",
  right: 5,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  padding: 10,
};
