import logo from './logo.svg';
import './App.css';
import Create from './chat/Create';
import { SocialIcon } from 'react-social-icons'


function App() {
  return (
    <div className="App">
    <div style={{position: "absolute", width: "100vw", top: 20, zIndex: 250}}>
      <div>
       <SocialIcon target="_blank" url="https://www.instagram.com/studizy_ai" style={{height: 40, width: 40, margin: 5}}/>
            <SocialIcon target="_blank" url="https://www.tiktok.com/@studizy" style={{height: 40, width: 40, margin: 5}}/>
      </div>
     <p style={{marginTop: 5, fontSize: 17, fontWeight: "bold"}}>Rejoins nous sur les réseaux,<br/>suis l'aventure Studizy en avant-première !</p>
      

    </div>
      <Create/>
    </div>
  );
}

export default App;
