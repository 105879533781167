import React from 'react';
import PropTypes from 'prop-types';
import AvatarElephant from './buddy.png';

const AvatarCtn = ({ styles }) => (
  <div style={styles.avatarCtn}>
    <img
      style={styles.avatar}
      src={AvatarElephant}
      alt="Avatar Elephant"
    />
  </div>
);

AvatarCtn.propTypes = {
  styles: PropTypes.shape({
    avatarCtn: PropTypes.object.isRequired,
    avatar: PropTypes.object.isRequired,
  }).isRequired,
};

export default AvatarCtn;
