import React, { useState } from "react";
import Chat from "./Chat.jsx";
import signUpData from "./messages.json";
import GradientBackground from "./GradientBackground.jsx";
import axios from "axios";
const SignUp = () => {
  console.log(signUpData);
  const colors = {
    primary: "#FFD700",
    yellow: "#FFFF00",
    dark: "#000000",
    pink: "#FFC0CB",
    light: "#FFFFFF",
    beige: "#F5F5DC",
  };
  const [currentMessage, setCurrentMessage] = useState(signUpData[0][0]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [textValue, setTextValue] = useState("");
  const [form, setForm] = useState({});

  const handleSubmit = (formData) => {
    console.log(" HEY JUDE ");
    setTextValue("stop-continue-btn");

    // const telegramBotToken = "7088809292:AAEpx3FOolLkwQLAg7X5LAO9_NNp0IaNToc";
    // const chatId = "-4244523502";
    // const text = `New form submission:\nName: ${formData.name}\nPhone: ${formData.phone}\nStudies: ${formData.studies}`;
    // axios
    //   .post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
    //     chat_id: chatId,
    //     text: text,
    //   })
    //   .then((response) => {
    //     console.log("Message sent successfully:", response.data);
    //     setCurrentMessage({
    //       text: " Merci de vous être inscrit ! On revient vers vous le plus vite possible, après de nombreuses demandes le processus de validation peut prendre jusqu'à 48h, mais je te jure qu'on fait au plus vite :)",
    //       type: "received",
    //       format: false,
    //     });
    //   })
    //   .catch((error) => {
    //     setCurrentMessage({
    //       text: " Une erreur s'est produite lors de l'envoi du message, veuillez réessayer plus tard. Si le problème persiste envois un mail à : beta@studizy.com",
    //       type: "received",
    //       format: false,
    //     });
    //     console.error("Error sending message:", error);
    //   });

    // Optionally, reset the form
    setForm({});
  };
  const submitValue = (value) => {
    console.log("submitting value", value);
    setForm({ ...form, [currentMessage.name]: value });
    setTextValue("");
    if (currentMessageIndex >= signUpData.length - 1) {
      console.log("submitting form", form);
      handleSubmit({ ...form, [currentMessage.name]: value });
    }
    if (value === "contact") {
      window.open("mailto:contact@studizy.com");
    } else if (value === "etudier") {
      setCurrentMessage(signUpData[currentMessageIndex + 1][0]);
      setCurrentMessageIndex(currentMessageIndex + 1);
    } else if (value === "ios") {
      setCurrentMessage({
        text: " T'as vraiment de la chance toi, c'est nos premiers jours sur l'App Store :) <linkios>",
        type: "received",
      });
    } else {
      setCurrentMessage({
        text: " On est en version bêta sur Android, donc bonne nouvelle tu peux déjà télécharger l'app en suivant les étapes suivantes: \n 1) Rejoindre le groupe google de testeurs \n 2) Télécharger l'app  : <linkandroid>",
        type: "received",
      });
    }
  };

  const next = () => {
    console.log("currentMessageIndex", currentMessageIndex);
    if (currentMessageIndex < signUpData.length - 1) {
      if (currentMessage.format) {
        submitValue(textValue);
      }
      setCurrentMessage(signUpData[currentMessageIndex + 1][0]);
      setCurrentMessageIndex(currentMessageIndex + 1);
    } else {
      if (currentMessage.format) {
        console.log("submitting value 2", textValue);
        submitValue(textValue);
      }
    }
  };

  return (
    <GradientBackground colors={[colors.primary, colors.yellow]}>
      <div
        style={{
          height: "100%",
          width: "100vw",
          justifyContent: "center",
          display: "flex",
        }}>
        <div
          style={{
            width: Math.min(500, window.innerWidth),
          }}>
          <Chat
            colors={colors}
            messages={[currentMessage]}
            textValue={textValue}
            setTextValue={setTextValue}
            submitValue={submitValue}
            next={next}
          />
        </div>
      </div>
    </GradientBackground>
  );
};

export default SignUp;
