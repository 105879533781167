const styles = {
  button: {
    padding: '13px',
    borderRadius: '13px',
    boxShadow: '0px 15px 7px rgba(0, 0, 0, 0.05)',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  buttonWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '0',
  },
  text: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  textWithIcon: {
    paddingLeft: '2px',
  },
};

export default styles;
