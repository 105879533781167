import React from "react";
import PhoneInput from "react-phone-input-2";
import Button from "./Button";
import { textInputStyle, phoneInputStyles } from "./chat.js";
import "react-phone-input-2/lib/style.css";

const InputComponent = ({
  message,
  textValue,
  onType,
  onSend,
  code,
  setCode,
  submitValue,
  placeholder,
  colors,
}) => {
  const setNumericCode = (value) => {
    if (value.length < 6) {
      setCode(value);
    } else {
      console.log("submitting code");
      setCode(value.slice(0, 6));
      onType(value.slice(0, 6));
    }
  };

  switch (message.format) {
    case "phone":
      return (
        <PhoneInput
          value={textValue}
          onChange={(phone) => onType(phone)}
          country={"be"}
          inputProps={{
            onKeyPress: (e) => {
              if (e.key === "Enter") {
                onSend();
              }
            },
            placeholder: " ",
            style: phoneInputStyles.textInput(colors),
          }}
          countryCodeEditable={false}
          dropdownStyle={{ borderRadius: 20 }}
          buttonStyle={phoneInputStyles.countryPickerButton(colors)}
        />
      );
    case "text":
      return (
        <input
          style={textInputStyle(colors)}
          onChange={(e) => onType(e.target.value)}
          value={textValue}
          type="text"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSend();
            }
          }}
          placeholder={placeholder ? placeholder : "..."}
        />
      );
    case "options":
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}>
          {message.options.map((option, index) => (
            <Button
              key={index}
              onClick={() => {
                submitValue(option.value);
              }}
              style={{
                borderRadius: 5,
                height: 70,
                width: "48%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                backgroundColor: "#7CA5A7",
              }}
              label={option.text}
              colors={colors}
            />
          ))}
        </div>
      );
    default:
      return null;
  }
};

export default InputComponent;
