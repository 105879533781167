const dimensions = {
  window: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
};

const createStyles = ({ colors }) => ({
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingBottom: 0,
    overflow: "visible",
  },
  btnCtn: {
    marginLeft: 40,
    width: dimensions.window.width - 80,
    position: "absolute",
    bottom: 50,
    zIndex: 10,
  },
  footerBg: {
    minWidth: dimensions.window.width,
    backgroundColor: "blue",
    height: 300,
    bottom: -200,
    paddingBottom: 200,
  },
  avatarCtn: {
    transition: "transform 2.5s ease-in-out",
    width: "100%",
    height: Math.min(300, dimensions.window.height * 0.25),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    maxHeight: Math.min(300, dimensions.window.height * 0.25),
    marginTop: -100,
    zIndex: 100,
    objectFit: "scale-down",
  },
});

export default createStyles;
